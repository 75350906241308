import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Label,
  Spinner,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"
import mark from "../../assets/images/cn.gif"
import Trash from "../../assets/images/trash.gif"

function Complaintbox() {
  const [modal_small, setmodal_small] = useState(false)

  const [modal_small2, setmodal_small2] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [form, setform] = useState([])

  function tog_small2(data) {
    setmodal_small2(!modal_small2)
    setform(data)
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [modal_small1, setmodal_small1] = useState(false)

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const [form1, setform1] = useState([])
  const [plans, setplans] = useState([])
  console.log(plans)
  const [planscount, setplanscount] = useState(0)
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)
  console.log(pageNumber)
  const getPlans = selected => {
    console.log(selected)
    var token = datas
    const bodydata = {
      skipDocs: selected || pageNumber,
      length: listPerPage,
    }
    axios
      .post(URLS.Getapprovedlocalnews, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setplans(res.data.localNews)
        setplanscount(res.data.countDocs)
        setIsLoading(false)
      })
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    setPageNumber(0)
    const bodydata = {
      skipDocs: 0,
      length: listPerPage,
    }
    axios
      .post(URLS.GetapprovedlocalnewsSearch + `${e.target.value}`, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            setplans(res.data.localNews)
            setplanscount(res.data.countDocs)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    getPlans()
    datass()
  }, [])

  const pagesVisited = pageNumber * listPerPage
  const lists = plans
  // const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(planscount / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
    console.log(selected)
    getPlans(selected)
  }

  const history = useHistory()

  const Agentid1 = data => {
    sessionStorage.setItem("approvednewsid", data._id)
    history.push("/ViewApprovedLocalNews")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  const roles = data.rolesAndPermission
  const roles1 = data.user.departementName

  const Agentid2 = data => {
    sessionStorage.setItem("newsid", data._id)
    history.push("/EditLocalNews")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }
  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }

  const DeleteBanner = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteNews + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [formg, setformg] = useState({ allcustomers: false })

  const handleChange = e => {
    const myUser = { ...formg }
    myUser[e.target.name] = e.target.checked
    setformg(myUser)
  }

  const handleSubmit2 = e => {
    e.preventDefault()
    EditNews2()
  }

  const EditNews2 = () => {
    var token = datas
    var formid = form._id
    const data = { allcustomers: formg.allcustomers }
    axios
      .put(URLS.Approvelocalnews + "/" + formid, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small2(false)
            setformg({
              allcustomers: false,
            })
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Paper Boys" breadcrumbItem="Approved News List" />

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Approved News List </CardTitle>
                </CardHeader>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <Spinner
                        style={{ marginTop: "120px" }}
                        className="ms-2"
                        animation="border"
                        color="primary"
                        size="lg"
                      />{" "}
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div>
                        <div className="table-responsive">
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={planssearch}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>User Name</th>
                                <th>Approved-Date/Time</th>
                                <th>IMAGE</th>
                                <th>HeadLine</th>
                                <th>News</th>
                                <th>Language Name</th>
                                <th>Likes</th>
                                <th>Views</th>
                                <th>Reports</th>
                                <th style={{ width: "100px" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <th>{(pageNumber - 1) * 5 + key + 6}</th>
                                  <td>{data.createrName}</td>
                                  <td>
                                    {data.newTimings.approveNewDate == "" ? (
                                      <> {data.logCreatedDate.slice(0, 10)} </>
                                    ) : (
                                      <>
                                        {" "}
                                        {data.newTimings.approveNewDate} /{" "}
                                        {data.newTimings.approveNewTime}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {data.imageType == "false" ? (
                                      <>
                                        <video
                                          controls
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                          src={URLS.Base + data.image}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "150px",
                                          }}
                                          src={URLS.Base + data.image}
                                        />
                                      </>
                                    )}
                                  </td>
                                  <td>{data.headLine}</td>
                                  <td>
                                    {data.description.slice(0, 30) + "..."}{" "}
                                  </td>
                                  <td>{data.languageName}</td>
                                  <td>{data.likesCount}</td>
                                  <td>{data.viewsCount}</td>
                                  <td>{data.reportsCount}</td>
                                  <td>
                                    {/* {roles.localNewsEdit === true ||
                                roles1 === "superadmin" ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        Agentid2(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="success"
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="bx bx-edit"
                                      ></i>
                                    </Button>
                                  </>
                                ) : (
                                  ""
                                )} */}

                                    <Button
                                      onClick={() => {
                                        tog_small2(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="success"
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="bx bxs-bell-plus"
                                      ></i>
                                    </Button>

                                    {roles.localNewsView === true ||
                                    roles1 === "superadmin" ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            Agentid1(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="info"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-eye"
                                          ></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {roles.localNewsDelete === true ||
                                    roles1 === "superadmin" ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="danger"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="bx bx-trash"
                                          ></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={planscount}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small2}
          toggle={() => {
            tog_small2()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Approved
            </h5>
            <button
              onClick={() => {
                setmodal_small2(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit2(e)
              }}
            >
              <Col md={12}>
                <img src={mark} width="100%"></img>
              </Col>
              <hr></hr>
              <div>
                <Input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={formg.allcustomers}
                  name="allcustomers"
                  required
                  value={formg.allcustomers}
                  onClick={e => {
                    handleChange(e)
                  }}
                  style={{ fontSize: "24px" }}
                  id="read"
                />

                <Label className="form-check-label mt-2 ml-3" for="read ">
                  Send Notification To All
                </Label>
              </div>
              <hr></hr>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small2(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Status Update
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Col md={12}>
                <img src={mark} width="100%"></img>
              </Col>
              {/* <Col md={12}>
                <div className="mb-3">
                  <Label> Status </Label>
                  <span className="text-danger">*</span>
                  <select
                    value={form1.status}
                    name="status"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option value="Approved">Approved</option>
                  </select>
                </div>
              </Col> */}
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Status Update
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Col md={12}>
                <img src={Trash} width="100%"></img>
              </Col>

              <Col md={12}></Col>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Complaintbox
